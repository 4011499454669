import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    component: () => import(/* webpackChunkName: "dashboard" */ '../layouts/main/index-layout.vue'),
    children: [
      {
        path: '/',
        name: 'home',
        meta: { title: 'Home' },
        component: () => import('../views/dashboard/index-page.vue'),
      },
      {
        path: '/city',
        name: 'city',
        meta: { title: 'Daerah' },
        component: () => import('../views/city/index-page.vue'),
      },
      {
        path: '/vehicle',
        name: 'vehicle',
        meta: { title: 'Truk' },
        component: () => import('../views/vehicle/index-page.vue'),
      },
      {
        path: '/pricing',
        name: 'pricing',
        meta: { title: 'Harga Pengiriman' },
        component: () => import('../views/pricing/index-page.vue'),
      },
      // {
      //   path: '/:pathMatch(.*)*',
      //   component: () => import('@/views/errors/NotFound.vue'),
      // },
    ],
  },
  {
    path: '/',
    component: () => import(/* webpackChunkName: "auth" */ '../layouts/auth/index-layout.vue'),
    children: [
      {
        path: '/login',
        name: 'login',
        meta: { title: 'Login' },
        component: () => import(/* webpackChunkName: "auth" */ '../views/auth/index-login.vue'),
      },
    ],
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes,
})

export default router
